import React from "react";
import Footer from "../../components/App/Footer";
import Layout from "../../components/App/Layout";
import Navbar from "../../components/App/Navbar";
import underConstuction from "../../assets/images/under_construction.svg";

const Careers = () => {
  return (
    <Layout>
      <Navbar />
      <div className="page">
        <section>
          <div className="container text-center">
            <img src={underConstuction} width="300" className="pt-4" alt="" />
            <h5 className="pt-4 pb-4">
              Sorry! This page is still under construction.
            </h5>
            <a href="/" className="default-btn no-icon mb-4">
              Return Home
            </a>
          </div>
        </section>
      </div>
      <Footer />
    </Layout>
  );
};

export default Careers;
